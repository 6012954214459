import React, { useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import useAppContext from "context/state";
import { signIn, signOut, useSession } from "next-auth/client";

const NewMenuIcon = () => (
  <div className="topnav-toggler-inner ">
    <i className="topnav-toggler-line" />
    <i className="topnav-toggler-line" />
    <i className="topnav-toggler-line" />
  </div>
);

const populateCurrentDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return yyyy + "-" + mm + "-" + dd;
};

const CustomNavbar = ({}) => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [session, loading] = useSession();
  const router = useRouter();
  const api = useAppContext();

  // Check for roles or site access
  const hasAdminOrManagementRole = ["ADMIN", "MANAGEMENT"].includes(
    session?.user?.plants?.ROLE,
  );
  const hasPowerAccess =
    session?.user?.plants?.SITE_ACCESS?.ADMIN?.length > 0 ||
    session?.user?.plants?.SITE_ACCESS?.EXECUTIVE?.length > 0;

  return (
    <div className="header">
      <>
        <ul
          className={
            click
              ? "navoptions active mb-0"
              : "navoptions d-flex ml-0 pl-0 mb-0"
          }
        >
          {["ADMIN", "SITE", "MANAGER", "MANAGEMENT"].includes(
            session?.user?.plants?.ROLE,
          ) ? (
            <li onClick={closeMobileMenu}>
              <Link
                href={`/dashboard?plant_id=${api.plant_dict[api.plant_id]}&start_date=${api.startDate}&end_date=${api.endDate}&date_range_flag=0&report_type=MTD`}
              >
                <a
                  className={
                    router.pathname === "/dashboard"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  Dashboard
                </a>
              </Link>
            </li>
          ) : null}
          {["SITE", "ADMIN", "MANAGER"].includes(
            session?.user?.plants?.ROLE,
          ) ? (
            <li onClick={closeMobileMenu}>
              <Link href={`/download`}>
                <a
                  className={
                    router.pathname === "/download"
                      ? "active nav-link"
                      : "nav-link"
                  }
                >
                  Download
                </a>
              </Link>
            </li>
          ) : null}
          {["ADMIN", "SITE"].includes(session?.user?.plants?.ROLE) ? (
            <li onClick={closeMobileMenu}>
              <Link
                href={`/input?plant_id=${api.plant_dict[api.plant_id]}&start_date=${api.startDate}&end_date=${api.endDate}&date_range_flag=0`}
              >
                <a
                  className={
                    router.pathname === "/input"
                      ? "active nav-link"
                      : "nav-link"
                  }
                >
                  Input
                </a>
              </Link>
            </li>
          ) : null}
        </ul>
      </>

      {/* Conditionally render Power dropdown based on user role or access */}
      

      {/* Conditionally render Forecast dropdown */}
      {/*["ADMIN", "MANAGEMENT"].includes(session?.user?.plants?.ROLE) ? (
        <div className="menudropdown d-none d-lg-block d-md-block text-white">
          <button className="menudropbtn text-white">
            Forecast <i className="fa fa-angle-down text-white"></i>
          </button>
          <ul className="menudropdown-content">
            <li>
              <Link
                href={`/forecast/dashboard?plant_id=${api.plant_dict[api.plant_id]}&start_date=${api.startDate}&end_date=${api.endDate}&date_range_flag=0&report_type=MTD`}
              >
                <a
                  className={
                    router.pathname === "/forecast/dashboard"
                      ? "active nav-link"
                      : "nav-link"
                  }
                >
                  Report
                </a>
              </Link>
            </li>
            <li>
              <Link
                href={`/forecast/input?plant_id=${api.plant_dict[api.plant_id]}&start_date=${api.startDate}&end_date=${api.endDate}&date_range_flag=0`}
              >
                <a
                  className={
                    router.pathname === "/forecast/input"
                      ? "active nav-link"
                      : "nav-link"
                  }
                >
                  Input
                </a>
              </Link>
            </li>
          </ul>
        </div>
      ) : null*/}

      {/* Mobile menu toggle */}
      <div id="mobilemenu" onClick={handleClick}>
        {click ? (
          <div className="menuicon close-topnav">
            <NewMenuIcon />
          </div>
        ) : (
          <div className="menuicon">
            <NewMenuIcon />
          </div>
        )}
      </div>
      <style jsx>
        {`
          .menudropbtn {
            background-color: transparent;
            padding: 16px;
            font-size: 16px;
            border: none;
            cursor: pointer;
            font-size: 1rem;
            font-weight: 600;
            z-index: 11;
          }

          .menudropdown {
            width: auto;
            position: relative;
            display: inline-block;
          }

          .menudropdown-content {
            display: none;
            position: absolute;
            background-color: #fff;
            min-width: 180px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 50;
            list-style: none;
            padding: 0;
            margin: 0;
          }

          .menudropdown-content a {
            color: #767676;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
            font-size: 1rem;
            font-weight: 600;
            z-index: 50;
          }

          .menudropdown-content li {
            border-bottom: 1px solid #f7f7f7;
          }

          .menudropdown:hover .menudropdown-content {
            display: block;
            z-index: 11;
          }

          .header {
            display: flex;
            justify-content: space-between;
            align-items: left;
          }
          .logonav {
            display: flex;
            align-items: left;
            justify-content: space-evenly;
          }
          .logocontainer {
            display: flex;
            align-items: left;
            justify-content: center;
          }
          .logo {
            width: 40px;
            height: 40px;
          }
          .navoptions {
            list-style-type: none;
          }
          .mobileoption {
            display: none;
          }

          .option :hover {
            color: #007bff;
          }
          a {
            font-size: 0.875rem;
            color: black;
          }
          .signinup {
            display: flex;
            padding: 0px 5px;
            list-style-type: none;
          }
          .signin {
            padding-right: 50px;
            align-self: center;
          }
          .signin :hover {
            color: white;
          }
          .signupbtn {
            padding: 10px 10px;
            height: 2.4rem;
            border-radius: 3px;
            background: rgb(222, 9, 241);
            color: white;
            cursor: pointer;
            align-items: center;
          }
          #mobilemenu {
            display: none;
          }
          .menuicon {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          @media (max-width: 800px) {
            /*Mobile View */

            .header {
              padding: 0px 10px;
            }
            .logo {
              width: 45px;
              height: 45px;
            }
            .navoptions {
              display: flex;
              width: 20rem;
              position: fixed;
              top: 60px;
              opacity: 1;
              flex-direction: column;
              list-style-type: none;
              grid-gap: 0px;
              transform: translateY(15px);
              z-index: -234567;
              transition: 0.3s transform;
              opacity: 0;
              left: 0;
            }

            .navoptions.active {
              opacity: 1;
              z-index: 150;
              padding-left: 0px;
              transform: translateY(0);
              -webkit-box-shadow: 0 0 10px 0 #ddd;
              box-shadow: 0 0 10px 0 #ddd;
            }

            .menuicon {
              width: 32px;
              height: 32px;
              padding: 4px;
            }
            .option {
              // display: flex;
              // align-items: center;
              // justify-content: center;
              // height: 10vw;
              // padding: 30px 0px;
            }
            .navoptions .option {
              padding: 0;
            }
            .signup {
              background: rgb(0, 0, 0, 1);
              border-radius: 3px;
              color: white;
              width: 100%;
              align-self: center;
              border-radius: 3px;
            }

            .signinup {
              display: none;
            }
            #mobilemenu {
              display: block;
            }
          }
        `}
      </style>
    </div>
  );
};

export default CustomNavbar;
