import React, {useState} from "react";
import { getSession } from "next-auth/client";
import Admin from "layouts/Admin.js";
import useAppContext from "context/state";
import { Button, Card, CardBody, Form, Container, Row, Col } from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import { useRouter } from "next/router";
import { signIn, signOut, useSession } from "next-auth/client";

function IndexPage()  {
  const logoFile = process.env.LOGO_FILE || "brookfield-blue.png";
  const [customer_login,setCustomerLogin] = useState(process.env.CUSTOMER_LOGIN)

  console.log("Component props:", { logoFile });
  const [session, loading] = useSession();
  const api = useAppContext();
  const router = useRouter();

  React.useEffect(() => {
    if (session) {
      router.push(
        `/dashboard?plant_id=${api.plant_dict[api.plant_id]}&start_date=${api.startDate}&end_date=${api.endDate}&date_range_flag=0&report_type=MTD`,
      );
    }
  }, [session]);

  return (
    <>
      
      {!session && (
        <>
          <AuthHeader title="Welcome to SolarDash!" lead="" />
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Col lg="5" md="7">
                <Card className="bg-secondary border-0 mb-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <img
                        src={`/${logoFile}`}
                        className="cmp-logo mr-2"
                        style={{
                          width: "20rem",
                          padding: "0.25rem",
                          margin: "0.25rem",
                        }}
                        alt=""
                      />
                    </div>
                    <Form role="form">
                      <div className="text-center">
                      {customer_login==true?<>
                              <Button
                                  className="my-4"
                                  color="info"
                                  type="button"
                                  onClick={() =>
                                      signIn("azure-ad-b2c", {}, { prompt: "login" })
                                  }
                              >
                                Customer Sign in
                              </Button>
                            </>:null }

                        <Button
                          className="my-4"
                          style={{ background: "#0B2741", color: "white" }}
                          type="button"
                          onClick={() => signIn("azure-ad", {}, {})}
                        >
                          Login
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}

      {session && (
        <>
          <style jsx global>
            {`
              .backtohome {
                position: absolute;
                right: 2rem;
                top: 1rem;
                color: #767676;
                font-weight: 600;
              }
              .footer {
                display: none;
              }
              .backdrop {
                display: none;
              }
              .carddivportfolio1 {
                max-height: calc(100vh - 9rem) !important;
              }
            `}
          </style>
        </>
      )}
    </>
  );
}


IndexPage.layout = Admin;

export default IndexPage;
